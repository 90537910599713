import { Link } from 'gatsby'
import React, { useState } from 'react'

const initialState = {
  results: [],
  query: '',
}

const getSearchResults = (query) => {
  const index = window.__FLEXSEARCH__.de.index
  const store = window.__FLEXSEARCH__.de.store

  if (!query || !index) {
    return []
  } else {
    let results = []

    Object.keys(index).forEach((idx) => {
      results.push(...index[idx].values.search(query))
    })

    results = Array.from(new Set(results))

    const nodes = store
      .filter((node) => results.includes(node.id) && node.node.slug !== '/')
      .map((node) => node.node)

    return nodes
  }
}

const Search = ({ className = '' }) => {
  const [state, setState] = useState(initialState)

  const onInput = (e) => {
    const searchInput = e.target.value

    if (searchInput.length > 2) {
      const results = getSearchResults(searchInput)
      setState({ results: results, query: searchInput })
    } else {
      setState({ results: [], query: searchInput })
    }
  }

  return (
    <div className={'search ' + className}>
      <input type="text" placeholder="Suche" onChange={onInput} />
      <ul className="taglist clean">
        {state.results.map(
          (page, idx) =>
            page.layout !== 'hidden' &&
            page.layout !== 'null' && (
              <li key={page.id} className="is-size-5">
                <Link to={page.slug}>{page.title}</Link>
              </li>
            )
        )}
      </ul>
    </div>
  )
}

export default Search
