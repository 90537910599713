import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Search from '../../components/Search'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/banner.jpg')`,
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '150px',
              lineHeight: '1',
              justifyContent: 'space-around',
              alignItems: 'left',
              flexDirection: 'column',
            }}
          >
            <h1 className="banner-text has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
              Aktuelles
            </h1>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <Search className={'blog'} />
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
